.tokenomics_row {
  width: 80%;
  margin: auto;
  margin-top: 0.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: none;
  background-color: #e07a67;
  padding: 0.4rem 2rem;
  color: white;
  border-radius: 2rem;
}
.tokenomics_row:hover {
  transform: scale(1.05);
  z-index: 1;
  /* margin-top: 0;  */
}
@media screen and (max-width: 600px) {
  .tokenomics_row {
    width: 90%;
  }
}
