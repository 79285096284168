.footer {
  width: 100%;
  background: #4c8769;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* border: solid white;  */
  align-items: center;
  padding-top: 2rem;
}

.logo {
  width: 10rem;
  margin-bottom: 0rem;
  cursor: pointer;
}

.roadmap {
  display: flex;
  gap: 1.2rem;
  list-style: none;
  margin-bottom: 2rem;
  flex-wrap: wrap;
  justify-content: center;
}
.roadmap > li > a {
  width: auto;
  color: #fff;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 19.227px; /* 150% */
  cursor: pointer;
}
.roadmap > p {
  cursor: pointer;
  font-size: 24px;
  background: linear-gradient(113deg, #fea010 0%, #d55116 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-style: normal;
  font-weight: 700;
  line-height: 25.636px; /* 106.818% */
  letter-spacing: 1.709px;
  text-transform: uppercase;
}

.social {
  display: flex;
  gap: 1rem;
  list-style: none;
  margin-bottom: 2rem;
  cursor: pointer;
}

.socialimg {
  transition: filter 0.3s ease;
  /* border: solid; */
}
.socialimg:hover {
  filter: invert(1);
  transform: scale(1.2)
}

@media screen and (max-width: 500px) {
  .roadmap {
    width: 50%;
  }
  .roadmap > li > a {
    font-size: 1.5rem;
  }
}
