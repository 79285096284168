.login {
  margin: 0;
  padding: 0;
}

.login_header {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #4c8769;
  height: auto;
  position: relative;
  min-height: 10vh;
}

.login_body {
  width: 100%;
  min-height: 80vh;
  background: #4c8769;
  /* position: relative; */
  background-image: url("../../assets/images/loginBg.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  padding: 5% 0%;
}

.login_containner {
  /* position: absolute; */
  width: 100%;
  top: 15%;
  height: auto;
  /* background: red; */
  margin: auto;
}

.login_containner > div {
  width: 40%;
  height: 100%;
  margin: auto;
  background: #4c8769;
  border-radius: 1.5rem;
  /* border: solid; */
  padding-bottom: 20px;
}

.login_title {
  width: 20%;
  /* border: solid white; */
  margin-top: -10%;
}

.login_form {
  width: 80%;
  margin: auto;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.login_input {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin: 3%;
}

.login_input > input {
  padding: 0.5rem 1rem;
  border-radius: 1rem;
  background: none;
  outline: none;
  border: solid 1px white;
  color: white;
}

.login_input > input::placeholder {
  color: white;
}

.password_input {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin: 3%;
}

.password_input >div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0rem 1rem;
  border-radius: 1rem;
  background: none;
  outline: none;
  border: solid 1px white;
  color: white;
}

.password_input >div> input {
  width: 80%;
  padding: 0.5rem 0rem;
  border-radius: 1rem;
  background: none;
  outline: none;
  border: none;
  color: white;
}

.password_input >div> span {
  font-size: x-large;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.password_input >div> input::placeholder {
  color: white;
}

.login_fp {
  color: black;
  text-align: right;
  margin: 0 3%;
  font-size: small;
  text-decoration-line: underline;
  cursor: pointer;
}

.login_btn {
  text-align: center;
  /* margin: auto; */
  margin: 3%;
}
.login_btn > button {
  width: 10rem;
  cursor: pointer;
  color: black;
  text-align: center;
  margin-bottom: 0.5rem;
  padding: 0.5rem 1rem;
  background: #02ae70;
  outline: none;
  border: none;
  font-weight: bold;
  border-radius: 1rem;
}

.login_register {
  cursor: pointer;
  color: black;
  text-align: center;
  /* margin: 1rem; */
  text-decoration-line: underline;
}

.login_footer {
  width: 100%;
  height: auto;
  min-height: 10vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #4c8769;
  gap: 1rem;
  padding: 1rem;
}
.socialimg {
  transition: filter 0.3s ease;
  cursor: pointer;
  /* border: solid; */
}
.socialimg:hover {
  filter: invert(1);
  transform: scale(1.2)
}

/* Styles for tablets and small laptops */
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  /* Add your CSS styles here for tablets and small laptops */
  .login_containner > div {
    width: 65%;
    height: 100%;
    margin: auto;
    background: #4c8769;
    border-radius: 1.5rem;
  }
  .login_form {
    font-size: x-large;
  }
  .login_input {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    margin: 4%;
    font-size:x-large;
  }
  .login_input > input {
    padding: 1rem 2rem;
    border-radius: 1rem;
    background: none;
    outline: none;
    border: solid 1px white;
    color: white;
  }
  .login_fp {
    margin: 4%;
  }
  .login_register{
    width: 80%;
    margin: auto;
  }
}

/* Styles for mobile devices */
@media only screen and (max-width: 767px) {
  /* Add your CSS styles here for mobile devices */
  .login_containner > div {
    width: 90%;
    height: auto;
    margin: auto;
    background: #4c8769;
    border-radius: 1.5rem;
  }
  .login_body{
    padding: 10vh 0;
    /* border: solid; */
  }
  .login_form {
    font-size: large;
  }
  .login_input {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    margin: 4%;
    font-size: large;
  }
  .login_input > input {
    padding: 1rem 2rem;
    border-radius: 1rem;
    background: none;
    outline: none;
    border: solid 1px white;
    color: white;
  }
  .login_fp {
    margin: 4%;
  }
  .login_register{
    width: 80%;
    margin: auto;
  }
}
