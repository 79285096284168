
.navbar {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 100;
  color: var(--text-white);
  max-width: 2300px;
  background: #fdcbd0;
  /* background: red; */
  margin: 0 !important;
  padding: 1.2% 2.5%;
  /* overflow: hidden; */
}

.Active {
  border-bottom: 2px solid var(--bg-300);
  padding-top: 5px;
}

.notActive {
  border-bottom: none;
}

.container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  z-index: 50;
  top: 0%;
  color: var(--text-white);
  max-width: 100%;
  max-height: auto;
  display: flex;
  justify-content: center;
  /* padding: 1.7% 0%; */
  background-color: none;
}
.logoContainer {
  display: flex;
  align-items: center;
  gap: 1rem;
}
.logo1 {
  width: 4rem !important;
  height: 4rem !important;
}
.logo {
  width: 100% !important;
  zoom: 2;
}

.navbar > ul {
  list-style: none;
  display: flex;
  align-items: center;
}

.navbar > ul:nth-child(1) {
  font-style: normal;
  font-weight: 800;
  font-size: 1.5rem;
  line-height: 2rem;
  gap: 1rem;
  height: 30px;
  color: #edab07;
}

.container > ul:nth-child(1) {
  gap: 2.938rem;
  font-style: normal;
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 1.688rem;
  color: #edab07;
}

.navbar > ul:nth-child(1) > li:nth-child(1) > img:first-child {
  height: 5rem;
}
.navbar > ul:nth-child(1) > li:nth-child(1) > img:nth-child(2) {
  height: 1.1rem;
}

.navbar li {
  cursor: pointer;
  height: 100%;
  display: flex;
  align-items: center;
}

.hide_draw {
  display: none;
}

#menu {
  display: none;
  flex-direction: column;
}

.LoginButton button {
  display: inline-flex;
  padding: 0.6rem 1rem;
  justify-content: center;
  align-items: center;
  border-radius: 1rem;
  background: #508c6a;
  border: none !important;
  color: white;
  font-weight: bold;
  cursor: pointer;
}
.connectButton button {
  cursor: pointer;
  background: #508c6a !important;
  padding: 0rem 1rem !important;
  border: none !important;
  outline: none !important;
  border-radius: 1rem !important;
  zoom: 0.9 !important;
}
.connectButton div {
  border: none !important;
  background: none !important;
}
.dropdown button{
  outline: none !important;
  border: none !important;
  background: none !important;
  /* padding: 0rem  !important; */
  color: #00030d;
  font-size: 14px;
  font-weight: bold;
  display: flex !important;
  align-items: center !important;
}
.dropdown ul{
  /* padding-left: 2rem; */
  margin-top: 0.2rem;
  margin-left: 1rem;
}
.Pagelinks {
  width: 30%;
  display: flex;
  justify-content: space-evenly;
  border: solid #b4420c 1px;
  /* padding: 1%; */
  border-radius: 1rem;
  background: #ffffff;
  color: black;
  /* zoom: 0.9 !important; */
}

.Pagelinks > li > a {
  color: #00030d;
  font-size: 14px;
  font-style: normal;
  font-weight: bold;
  line-height: normal;
  padding: 0% 2%;
}

.Pagelinks > li > a:hover {
  background: linear-gradient(
    118deg,
    #ff4608 7.26%,
    #9905ff 91.98%,
    #9801ff 100%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

@media screen and (max-width: 600px) {
  .container {
    width: 100%;
    background: #fdcbd0;
  }
  .navbar > ul:nth-child(2) {
    display: none;
  }
  .connectButton button {
    padding: 0rem 1rem !important;
    border: none !important;
    outline: none !important;
    zoom: 0.8 !important;
  }

  .connectButton div {
    border: none !important;
    background: none !important;
  }

  #menu {
    display: block;
    color: #020202;
  }

  .navbar {
    width: 80%;
    background: #fdcbd0;
  }

  div #drawerContainer {
    position: fixed;
    display: flex;
    flex-direction: column;
    margin-top: 2rem;
    gap: 1.5rem;
    color: var(--text-white);
    list-style: none;
    align-items: center;
    z-index: 1;
    left: 0;
    height: 100vh;
    width: 60%;
    top: 0px;
    padding-top: 30px;
    background-color: var(--bg-500);
    box-sizing: border-box;
    transition: 0.2s ease-in-out;
    /* border: solid black 1px; */
    background: #fdcbd0;
  }
  div #drawerContainer_hide {
    position: fixed;
    display: flex;
    flex-direction: column;
    gap: 2.5rem;
    color: var(--text-white);
    list-style: none;
    align-items: center;
    z-index: 300;
    left: -100%;
    height: 100vh;
    width: 60%;
    top: 0px;
    background-color: var(--bg-500);
    padding: 30px 0px;
    box-sizing: border-box;
    border-radius: 0px 10px 10px 0px;
    transition: all 0.3s ease-out;
  }

  #drawerContainer > li {
    width: 100%;
    font-style: normal;
    font-weight: bold;
    font-size: 2rem;
    line-height: 1.688rem;
    cursor: pointer;
    text-align: center;
    color: #508c6a;
    padding: 1rem 0px;
    /* border-radius: 0px 10px 10px 0px; */
  }
}
