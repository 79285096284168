@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');

* {
  margin: 0px;
  padding: 0px;
  font-family: 'Inter', sans-serif;
  box-sizing: border-box;
}
@font-face {
  font-family: 'MyFont'; /* The font family name you want to use */
  src: url('./assets/fonts/segoesc.ttf') format('truetype'); /* Adjust the path to your TTF file */
  /* You can add more font formats (e.g., woff, woff2) for better cross-browser compatibility */
}

body {
  max-width: 100%;
  margin: auto;
}
.disable-scroll {
  overflow: hidden;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

input {
  -moz-appearance: textfield;
}

:root {
  /* --bg-50: #645a9a;
  --bg-100: #413a69;
  --bg-200: #332d53;
  --bg-300: #2d274a;
  --bg-400: #241f3d;
  --bg-500: #282248;
  --bg-600: #050430;
  --gradient: linear-gradient(95.79deg, rgba(220, 208, 208, 0.2) 4.98%, rgba(255, 252, 252, 0) 91.42%), #F8B100;;
  --text-grey-100: rgba(255, 255, 255, 0.8);
  --text-grey-200: rgba(255, 255, 255, 0.65);
  --text-grey-300: rgba(255, 255, 255, 0.33);
  --text-white: #ffffff; */
  --primary: #f8b307;
  --secondary: #0d0d0d;
  --text: #ffffff;
  --bg-black: #10100b;
  --bg-gold: linear-gradient(95.79deg, rgba(220, 208, 208, 0.2) 4.98%, rgba(255, 252, 252, 0) 91.42%), #f8b100;
  --bg-grey: linear-gradient(90deg, rgba(217, 217, 217, 0.1) 0%, rgba(217, 217, 217, 0.1) 103.73%);
}

@media screen and (max-width: 1300px) {
  :root {
    font-size: 14px;
  }
}

@media screen and (max-width: 1000px) {
  :root {
    font-size: 12px;
  }
}

@media screen and (max-width: 800px) {
  :root {
    font-size: 10px;
  }
}

@media screen and (max-width: 400px) {
  :root {
    font-size: 8px;
  }
}
