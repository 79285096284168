@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Sedgwick+Ave&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Segoe+Script&display=swap');

.main {
  margin: 0;
  padding: 0;
  /* background: #FDCBD0; */
  background: #4c8769;
  overflow-x: hidden;
}
.legacy {
  width: 100%;
  height: auto;
}

.legacy > img {
  width: 100%;
}

.legacy > div {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  width: 100%;
}


.externalLink {
  position: absolute !important;
  left: 50% !important;
  top: 90% !important;
  transform: translateX(-50%) !important;
  display: flex;
  gap: 4rem;
  justify-content: center;
  z-index: 100;
}

.externalLink > button {
  padding: 0.5rem 2rem;
  outline: none;
  border: none;
  background-color: #e2a457;
  font-weight: bold;
  cursor: pointer;
  border-radius: 0.5rem;
}

.legacy > div > div {
  width: 75%;
  text-align: center;
  margin: auto;
  /* background-color: rgba(0, 0, 0, 0.5); */
  border-radius: 1rem;
}
.legacy > div > div > p {
  /* color: #fff; */
  font-family: 'MyFont';
  font-size: 40px;
  font-style: italic;
  font-weight: 700;
  line-height: normal;
  color: white;
  border-radius: 1rem;
  padding: 0.5rem;
  text-transform: capitalize;
}

.gradientTxt {
  /* color: #ff700a42; */
  --clr: #fff;
  --clr2: #fa8606;
  background-clip: text;
  background: var(--clr) -webkit-gradient(linear, left top, right top, from(var(--clr2)), to(var(--clr2)), color-stop(0.5, var(--clr2))) 0 0 no-repeat;
  -webkit-background-clip: text;
  background-size: 120px;
  -webkit-background-size: 120px;
  -webkit-text-fill-color: transparent;
  animation: animate-gradientTxt 8s infinite;
}
@keyframes animate-gradientTxt {
  0% {
    background-position: left;
  }
  100% {
    background-position: right;
  }
}

.heading {
  width: auto;
  display: flex;
  justify-content: center;
  align-items: end;
}
.heading > h1 {
  width: auto;
  color: #121212;
  font-family: Comic Sans MS;
  font-size: 38px;
  font-style: normal;
  font-weight: 700;
  font-style: italic;
  background-color: #e6a758;
  border-top-right-radius: 1rem;
  border-bottom-right-radius: 1rem;
  padding: 0 1rem;
  padding-right: 8rem;
}
.heading > img {
  width: 7rem;
  /* border: solid; */
  margin-left: -7rem;
}
.headingido {
  width: auto;
  display: flex;
  justify-content: center;
  align-items: end;
}
.headingido > h1 {
  width: auto;
  color: #f3f4f8;
  font-family: Comic Sans MS;
  font-size: 38px;
  font-style: normal;
  font-weight: 700;
  font-style: italic;
  background-color: #4c8769;
  border-top-right-radius: 1rem;
  border-bottom-right-radius: 1rem;
  padding: 0 1rem;
  padding-right: 8rem;
}
.headingido > img {
  width: 7rem;
  /* border: solid; */
  margin-left: -7rem;
}
.heading2 {
  color: #fbfaf9;
  font-family: Poppins;
  font-size: 26px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  background: linear-gradient(133deg, #d95019 0%, #7538b9 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  padding: 1rem 0rem;
}

.intro_p {
  color: #fef9f9;
  font-family: 'MyFont';
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 2.5rem; /* 63.84px */
}

.tokenomics01_Div {
  background-color: #edd69c;
  padding: 2rem 0rem;
  background-image: url('../../assets/images/tokenomic-bg.png');
  background-position: center;
  background-size: contain;
}

.tokenomics_gif {
  width: 100%;
  border: solid 1px #e6a758;
  overflow: hidden;
  border-radius: 1rem;
}

.tokenomics_gif > img {
  width: 100%;
  transition: transform 0.3s ease;
  transform: scale(1.01);
  /* zoom: 1.4; */
}

.tokenomics01 {
  width: 80%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: auto;
  /* border: solid; */
  padding: 2rem 0rem;
}
.tokenomics01 > div:nth-child(1) {
  width: 50%;
  margin: auto;
  position: relative;
  /* border: solid; */
}
.tokenomics01 > div:nth-child(2) {
  width: 50%;
  margin: auto;
  /* border: solid; */
}
.comingsoon_Div {
  background-color: #f78570;
  padding: 2rem 0rem;
}

.comingsoon {
  width: 80%;
  display: flex;
  padding: 2rem 0rem;
  /* justify-content: space-between; */
  /* flex-wrap: wrap; */
  gap: 4rem;
  margin: auto;
  /* border: solid; */
  font-size: larger;
}
.comingsoon > div {
  width: 50%;
  align-items: center;
  /* border: solid; */
  display: flex;
  flex-direction: row;
  gap: 1rem;
  justify-content: center;
  text-align: center;
}
.comingsoon > div > div {
  /* border: solid; */
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: center;
  text-align: center;
}
.comingsoon > div > img {
  text-align: center;
}
.ido_Div {
  background-color: #e6a758;
  padding: 2rem 0rem;
  position: relative;
}

.idocomingsoon {
  position: absolute;
  top: 30%;
  z-index: 10;
  width: 100%;
  /* border: solid red; */
}
.idocomingsoon > div {
  width: 100%;
  text-align: center;
  /* border: solid white; */
}
.idocomingsoon > div > img {
  width: 40%;
  margin: auto;
}

.ido {
  width: 90%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: auto;
  /* margin-bottom: 3rem; */
  padding: 1rem 0rem;
}
.ido > div:nth-child(1) {
  width: 50%;
  height: auto;
  margin: auto;
  /* padding: 2rem; */
  /* padding: 3rem 0rem; */
  /* background: blue; */
  /* border: solid white; */
  filter: blur(8px);
}
.ido > div:nth-child(2) {
  width: 50%;
  height: auto;
  margin: auto;
  padding: 2rem;
  /* padding: 3rem 0rem; */
  /* background: blue; */
  filter: blur(8px);
}
.intro_p2 {
  color: #fef9f9;
  font-family: 'MyFont';
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.4; /* 63.84px */
}
.ido_presale {
  width: 100%;
  height: auto;
  background: #4c8769;
  border: solid black 1px;
  border-radius: 0.5rem;
  text-align: center;
}
.presale_t1 {
  color: #e3a557;
  font-family: Comic Sans MS;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 1rem 0rem;
}
.presale_t2 {
  color: #111;
  font-family: Comic Sans MS;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 1rem 0rem;
}
.presale_t3 {
  color: #111;
  font-family: Comic Sans MS;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0.5rem 0rem;
}
.presale_input {
  width: 70%;
  border: solid 1px white;
  margin: auto;
  margin-top: 2rem;
  display: flex;
  justify-content: space-between;
  color: white;
  border-radius: 1rem;
  overflow: hidden;
}
.presale_input > input {
  width: 85%;
  margin: auto;
  color: white !important;
  padding: 0.3rem 1rem;
  background: none;
  outline: none;
  border: none;
}
.presale_input > div {
  width: 15%;
  margin: auto;
}
.presale_input input::placeholder {
  color: #fffefe; /* Replace with the desired color value */
}
.presale_input > div {
  display: flex;
  flex-direction: column;
}
.presale_input > div > button {
  padding: 0rem 1rem;
  outline: none;
  border: none;
  background-color: #e2a457;
  font-weight: bold;
  cursor: pointer;
}
.presale_input > div > button:first-child {
  border-bottom: white solid 1px;
  cursor: pointer;
}
.presale_btn1 {
  width: 70%;
  /* border: solid 1px white; */
  margin: auto;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  display: flex;
  justify-content: space-between;
  color: white;
  border-radius: 1rem;
  overflow: hidden;
  background: #4c8769;
}
.presale_btn1 > button {
  color: white;
  width: 100%;
  border: none;
  background: #e2a457;
  outline: none;
  padding: 0.4rem 0rem;
  cursor: pointer;
}
.presale_btn2 {
  width: 20%;
  /* border: solid 1px white; */
  margin: auto;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
  display: flex;
  justify-content: space-between;
  color: white;
  border-radius: 1rem;
  overflow: hidden;
  background: #4c8769;
}
.presale_btn2 > button {
  color: black;
  width: 100%;
  border: none;
  background: #e2a457;
  outline: none;
  padding: 0.4rem 0rem;
  cursor: pointer;
}
.ido > div:nth-child(1) {
  width: 40%;
  margin: auto;
  padding: 1rem 0rem;
  position: relative;
  /* animation: slideFromRight 1s ease-in-out; */
}
.aboutus {
  width: 80%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: auto;
  /* margin-bottom: 3rem; */
}
.aboutus > div:first-child {
  width: 40%;
  margin: auto;
  /* padding: 1rem 0rem; */
}
.aboutus > div:first-child > img {
  width: 100%;
}
.aboutus > div:nth-child(2) {
  width: 50%;
  margin: auto;
  padding: 1rem 0rem;
  position: relative;
  /* animation: slideFromRight 1s ease-in-out; */
}
.intro_button_div {
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 1rem;
}
.intro_button_div > button {
  display: inline-flex;
  padding: 0.7rem 1.3rem;
  justify-content: center;
  align-items: center;
  border: none;
  outline: none;
  font-weight: bold;
  border-radius: 2rem;
  margin-top: 1rem;
}

.intro_button_div > button:nth-child(1) {
  background: linear-gradient(125deg, #7538b9 11.92%, #ee6910 83.65%);
}
.intro_button_div > button:nth-child(1):hover {
  transform: scale(1.2);
  z-index: 1;
  /* margin-top: 0;  */
}

.intro_button_div > button:nth-child(2) {
  background: none;
  color: white;
  border: solid 1px white;
}

.pumping {
  animation-name: pumping-effect;
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}
.pumping2 {
  animation-name: pumping-effect2;
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}
.nft_p {
  font-family: 'MyFont';
  margin-top: 1rem;
  font-weight: bold;
  color: black;
}

.tokenomics_table {
  width: 100%;
  transition: transform 0.3s ease-in-out;
}

.tokenomics_row_head {
  width: 80%;
  margin: auto;
  display: flex;
  justify-content: space-between;
  background-color: #4c8769;
  padding: 0.5rem 2rem;
  color: white;
  border-radius: 2rem;
  font-weight: bold;
  align-items: center;
}

@keyframes pumping-effect2 {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.03);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes pumping-effect {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
/* YourComponent.module.css */
.imageTransition {
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.imageTransition.show {
  opacity: 1;
}

@media screen and (max-width: 600px) {
  .legacy {
    height: 90vh;
  }

  .legacy > img {
    height: 100%;
    object-fit: cover;
    width: 100%;
  }
  .legacy > div {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    padding: 2rem;
    border-radius: 2rem;
  }

  .legacy > div > div {
    width: 90%;
  }
  .legacy > div > div > p {
    font-size: 30px;
    background: rgb(0, 0, 0, 0.4);
  }
  .heading {
    font-size: 32px;
    padding: 3rem 0rem;
  }
  .intro_p {
    font-size: 20px;
    text-align: center;
    padding: 2rem 0rem;
    line-height: 3.5rem;
  }
  .heading2 {
    font-size: 19px;
    padding: 1rem 0rem;
    text-align: center;
  }
  .aboutus {
    width: 80%;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .aboutus > div:first-child {
    width: 100%;
    margin: auto;
  }
  .aboutus > div:first-child > img {
    width: 100%;
  }
  .aboutus > div:nth-child(2) {
    width: 100%;
    margin: auto;
  }
  .ido {
    width: 90%;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .idocomingsoon > div > img {
    width: 80%;
    margin: auto;
  }
  .ido > div:nth-child(2) {
    width: 100%;
    margin: auto;
    height: auto;
    margin: auto;
    padding: 2rem 1rem;
  }
  .ido > div:nth-child(1) {
    width: 90%;
    margin: auto;
    display: none;
  }

  .tokenomics_row_head {
    width: 90%;
  }

  .tokenomics01 {
    width: 100%;
    display: flex;
    flex-direction: column-reverse;
    margin: auto;
  }
  .tokenomics01 > div:nth-child(1) {
    width: 90%;
    margin: auto;
    margin-top: 2rem;
  }
  .tokenomics01 > div:nth-child(2) {
    width: 90%;
    margin: auto;
  }
  .comingsoon {
    width: 90%;
    margin: auto;
    font-size: large;
    flex-direction: column;
  }
  .comingsoon > div:first-child {
    width: 50%;
    margin: auto;
    flex-direction: column-reverse;
  }
  .comingsoon > div:nth-child(2) {
    width: 50%;
    margin: auto;
    flex-direction: column;
  }
  .comingsoon > div > div {
    /* border: solid; */
    display: flex;
    flex-direction: row;
    gap: 1rem;
    justify-content: center;
    text-align: center;
  }

  .gradientTxt {
    background-size: 30px;
    -webkit-background-size: 150px;
    -webkit-text-fill-color: transparent;
    animation: animate-gradientTxt 10s infinite;
  }
  @keyframes animate-gradientTxt {
    0% {
      background-position: left;
    }
    100% {
      background-position: right;
    }
  }
}
